<template>
    <div class="wrapper">
        <div class="flex ac jb">
            <div>
                <div class="cs-box1 flex ac jb">
                    <div class="every-cs1">
                        <div class="pp1">今日销售</div>
                        <div class="pp-money">¥{{saleObj1.today_price}}</div>
                        <div class="pp2">订单数{{saleObj1.today_order}}笔</div>
                    </div>
                    <div class="every-cs2">
                        <div class="pp1">{{saleObj1.year}}年度</div>
                        <div class="pp-money">¥{{saleObj1.year_price}}</div>
                        <div class="pp2">去年¥{{saleObj1.last_year_price}}</div>
                    </div>
                    <div class="every-cs3">
                        <div class="pp1">{{saleObj1.month}}月份</div>
                        <div class="pp-money">¥{{saleObj1.month_price}}</div>
                        <div class="pp2">上月¥{{saleObj1.last_month_price}}</div>
                    </div>
                </div>
                <div class="cs-box2">
                    <div class="flex ac jb">
                        <div class="left-img">
                            <img class="title-img" src="../assets/sale/yrxph.png" alt="c">
                        </div>
                        <div class="right-select2 all-date-box flex ac jb">
                            <el-select v-model="Jqvalue" placeholder="景区选择" size="mini"
                                       clearable @change="changeJq">
                                <el-option
                                        v-for="item in Jqoptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>

<!--                            <el-date-picker-->
<!--                                    style="width: calc(50% - 8px);height: 28px"-->
<!--                                    v-model="month"-->
<!--                                    type="month"-->
<!--                                    placeholder="选择月"-->
<!--                                    @change="handleMothon"-->
<!--                                    value-format="yyyy-MM"-->
<!--                            >-->
<!--                            </el-date-picker>-->
                            <div class="left_date all-date-box" style="margin-left: 10px">
                                <el-date-picker
                                        v-model="date1"
                                        type="daterange"
                                        range-separator="-"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        :picker-options="pickerOptions"
                                        value-format="yyyy-MM-dd"
                                        placement="bottom-start"
                                        size="small"
                                        style="width: 100%"
                                        @change="handleDate1"
                                ></el-date-picker>
                            </div>

                            <el-popover
                                    placement="bottom"
                                    width="480"
                                    v-model="kjvisible1">
                                <div>
                                    <div class="chooseYearBox flex ac">
                                        <div style="margin-right: 5px">选择年份:</div>
                                        <el-date-picker
                                                v-model="yearValue1"
                                                type="year"
                                                placeholder="选择年"
                                                @change="chooseYaer1"
                                                format="yyyy"
                                                value-format="yyyy"
                                        >
                                        </el-date-picker>
                                    </div>
                                    <div class="month-box flex fw" style="margin-top: 5px">
                                        <div style="margin-right: 5px">选择月份:</div>
                                        <div style="width: 80%">
                                            <el-checkbox-group v-model="monthValue1" size="mini" :disabled="checkDisable"
                                                               :max="1"
                                                               @change="handleMonth">
                                                <el-checkbox label="01" border>一月</el-checkbox>
                                                <el-checkbox label="02" border>二月</el-checkbox>
                                                <el-checkbox label="03" border>三月</el-checkbox>
                                                <el-checkbox label="04" border>四月</el-checkbox>
                                                <el-checkbox label="05" border>五月</el-checkbox>
                                                <el-checkbox label="06" border>六月</el-checkbox>
                                                <el-checkbox label="07" border>七月</el-checkbox>
                                                <el-checkbox label="08" border>八月</el-checkbox>
                                                <el-checkbox label="09" border>九月</el-checkbox>
                                                <el-checkbox label="10" border>十月</el-checkbox>
                                                <el-checkbox label="11" border>十一月</el-checkbox>
                                                <el-checkbox label="12" border>十二月</el-checkbox>
                                            </el-checkbox-group>
                                        </div>

                                    </div>
                                    <div class="month-box flex fw">
                                        <div style="margin-right: 5px">选择节假日:</div>
                                        <div style="width: 80%">
                                            <el-checkbox-group v-model="holidayValue1" size="mini" :disabled="checkDisable2"
                                                               :max="1"
                                                               @change="handleHoliday">
                                                <div class="flex ac fw">
                                                    <div v-for="item in holidayList" :key="item.id"
                                                         style="margin-right: 5px">
                                                        <el-checkbox :label="item.name" border/>
                                                    </div>
                                                </div>
                                            </el-checkbox-group>
                                        </div>
                                    </div>
                                </div>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="cannelKj(1)">取消</el-button>
                                    <el-button type="primary" size="mini" @click="sumbitKj(1)">确定</el-button>
                                </div>
                                <div class="kj-box" slot="reference">
                                    <p v-if="monthValue1.length>0">{{yearValue1}} {{monthValue1.toString()}}</p>
                                    <p v-else-if="holidayValue1.length>0">{{yearValue1}} {{holidayValue1.toString()}}</p>
                                    <p v-else>快捷筛选</p>
                                </div>
                            </el-popover>
                        </div>
                    </div>
                    <div>
                        <div class="table-head flex ac">
                            <div class="head1">产品名称</div>
                            <div class="head2">景区</div>
                            <!--                            <div class="head3">购买率-->
                            <!--                                <img src="../assets/sale/yxUp.png" alt="up"></div>-->
                            <div class="head4">销售总价
<!--                                <img src="../assets/sale/yxUp.png" alt="up">-->
                            </div>
                            <div class="head5"><span>销售数量</span>
<!--                                <img src="../assets/sale/yxUp.png" alt="up">-->
                            </div>
                        </div>
                        <div class="imp-info">
                            <div class="table-info flex ac" v-for="(item,index) in rankList" :key="index">
                                <div class="head1">{{item.goods_name}}</div>
                                <div class="head2">{{item.scenic_spot_name}}</div>
                                <!--                                <div class="head3">85%</div>-->
                                <div class="head4">¥{{item.format_price}}</div>
                                <div class="head5">{{item.total_num}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="cs-box3">
                <div class="right1">
                    <div class="flex ac jb">
                        <img class="yxssj" src="../assets/sale/yxssj.png" alt="">
                        <div class="right-select">
                            <el-date-picker
                                    style="width: 100%"
                                    v-model="month2"
                                    type="month"
                                    placeholder="选择月"
                                    value-format="yyyy-MM"
                                    @change="handleMothon2"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div v-loading="loading"
                         element-loading-text="拼命加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(1,17,46, 0.8)">
                        <Echart
                                :options="options1"
                                id="bottom1Chart"
                                height="185px"
                                width="448px"
                        ></Echart>
                    </div>
                </div>
                <div class="right2">
                    <div class="flex ac jb">
                        <img class="flsj" src="../assets/sale/flsj.png" alt="">
                        <div @click="handleJump(0)" class="cr">
                            <img class="flsjMore" src="../assets/sale/flsjMore.png" alt="">
                        </div>
                    </div>

                    <div class="flex ac jb">
                        <div class="detail1">
                            <p class="pp3">门票系统(元)</p>
                            <div class="middle-box" v-if="typeList[0]">
                                {{typeList[0].format_price || 0 }}
                            </div>
                            <div class="bottom-box cr" @click="handleJump(1)">详情></div>
                        </div>
                        <div class="detail1">
                            <p class="pp3">嗨玩商城(元)</p>
                            <div class="middle-box" v-if="typeList[2]">
                                {{typeList[2].format_price || 0 }}
                            </div>
                            <div class="bottom-box cr" @click="handleJump(4)">详情></div>
                        </div>
<!--                        <div class="detail1">-->
<!--                            <p class="pp3">年卡累计(元)</p>-->
<!--                            <div class="middle-box" v-if="typeList[3]">-->
<!--                                {{typeList[3].format_price || 0 }}-->
<!--                            </div>-->
<!--                            <div class="bottom-box cr" @click="handleJump(3)">详情></div>-->
<!--                        </div>-->
                        <div class="detail1">
                            <p class="pp3">客如云商户(元)</p>
                            <div class="middle-box" v-if="typeList[1]">
                                {{typeList[1].format_price || 0 }}
                            </div>
                            <div class="bottom-box cr" @click="handleJump(2)">详情></div>
                        </div>
                    </div>
                </div>
                <div class="right3">
                    <img class="jrjqsj" src="../assets/sale/jrjqsj.jpg" alt="jrjqsj">
                    <div>
                        <div class="all-today-jq flex ac jb" v-for="(item,index) in spotDataList" :key="index">
                            <div class="fenduan1">{{item.scenic_spot_name}}</div>
                            <div class="fenduan2">
                                <div class="per-box" :style="{ width: item.percent + '%' }"></div>
                                <div class="dian" :style="{ left: item.percent + '%' }">
                                </div>
                            </div>
                            <div class="fenduan3">
                                {{item.format_price}} <span>元</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {
        tjSaleTotal,
        tjSaleMonthRank,
        tjSaleTypeData,
        tjSaleMonthData,
        tjSaleScenicSpotData,
        tjScenicSpotList,
        tjParkList
    } from "@/api";
    import {formatTime} from "../utils/index.js";

    import Echart from '../common/echart'
    import {tjHolidayList} from "../api";

    export default {
        components: {
            Echart,
        },
        props: {},
        data() {
            return {
                Jqvalue: 0,
                Jqoptions: [],
                month: formatTime(new Date(), "yyyy-MM"),
                month2: formatTime(new Date(), "yyyy-MM"),
                saleObj1: {},
                rankList: [],
                typeList: [],
                dateList: [],
                options1: {},
                xData: [],
                yData: [],
                yData2: [],
                spotDataList: [],
                date1: [],
                loading: true,
                pickerOptions: { // 限制日期范围为一个月
                    onPick: ({maxDate, minDate}) => {
                        this.isRestart = false;
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = "";
                        }
                    },

                    disabledDate: (time) => {
                        if (this.pickerMinDate !== "") {
                            const one = 3 * 31 * 24 * 3600 * 1000;
                            const minTime = this.pickerMinDate - one;
                            const maxTime = this.pickerMinDate + one;
                            return time.getTime() < minTime || time.getTime() > maxTime;
                        }
                    },
                },
                kjvisible1: false,
                yearValue1: new Date().getFullYear().toString(),
                monthValue1: [],
                holidayList: [],
                holidayValue1: [],
                checkDisable: false,
                checkDisable2: false
            };
        },
        computed: {},
        mounted() {
            // this.getTjSaleMonthData()
        },
        created() {
            let end = formatTime(new Date(), "yyyy-MM-dd");
            let start = formatTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30), "yyyy-MM-dd");
            this.date1 = [start, end];
            this.getTjSaleTotal()
            this.getTjScenicSpotList()
            this.getTjScenicSpotList2()
            this.getTjSaleMonthRank()
            this.getTjSaleTypeData()
            this.getTjSaleMonthData(this.month2)
            this.getTjSaleScenicSpotData()
            this.getTjHolidayList(new Date().getFullYear());
        },
        methods: {
            // 获取景区数据
            getTjScenicSpotList() {
                tjScenicSpotList().then(res => {
                    this.Jqoptions = []
                    this.Jqoptions = res.data
                    const obj = {
                        id: 0,
                        name: '全部',
                    }
                    this.Jqoptions.unshift(obj)
                })
            },
            // 获取景区数据
            getTjScenicSpotList2() {
                tjParkList().then(res => {
                    // this.Jqoptions = []
                    // this.Jqoptions = res.data
                    // const obj = {
                    //     id: 0,
                    //     name: '全部',
                    // }
                    // this.Jqoptions.unshift(obj)
                    console.log('res',res)
                })
            },
            // 选择景区
            changeJq() {
                this.getTjSaleMonthRank()
            },
            // handleMothon(val) {
            //     this.getTjSaleMonthRank(val, this.Jqvalue)
            // },
            handleMothon2(val) {
                this.getTjSaleMonthData(val)
            },
            // 获取景区排行数据
            getTjSaleMonthRank() {
                tjSaleMonthRank({start_date: this.date1[0], end_date:this.date1[1], scenic_spot_id: this.Jqvalue}).then(res => {
                    this.rankList = res.data
                })
            },
            // 获取景区排行数据
            getTjSaleTotal() {
                tjSaleTotal().then(res => {
                    this.saleObj1 = res.data
                })
            },
            // 获取分类数据
            getTjSaleTypeData() {
                tjSaleTypeData().then(res => {
                    this.typeList = res.data.type_list
                })
            },
            // 获取月销售数据
            getTjSaleMonthData(month) {
                tjSaleMonthData({month: month}).then(res => {
                    console.log('=====>', res)
                    this.dateList = []
                    this.dateList = res.data
                    this.xData = []
                    this.yData = []
                    this.yData2 = []
                    this.dateList.forEach(item => {
                        this.xData.push(item.d)
                        this.yData.push(item.total_price)
                        this.yData2.push(item.order_num)
                    })
                }).then(() => {
                    this.loading = false
                    this.getOptions()
                })
            },
            // 构建月销售图表
            getOptions() {
                this.options1 = {
                    // backgroundColor: '#00265f',
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            lineStyle: {
                                color: '#57617B'
                            }
                        },
                        formatter: '<strong> {b0}</strong> <br /> <span style="display: inline-block; margin-right: 4px; border-radius: 10px; width: 10px; height: 10px;background-color: #5470c6;"></span>门票收入：<strong>{c0}</strong>元<br /> <span style="display: inline-block; margin-right: 4px; border-radius: 10px; width: 10px; height: 10px;background-color: #5470c6;"></span>项目收入：<strong>{c1}</strong>元'
                        // formatter: '<strong> {b0}</strong> <br />'
                    },
                    // dataZoom: [{
                    //     type: 'slider', // 设置为滑动条型式
                    //     show: true, // 显示dataZoom组件
                    //     start: 0, // 默认显示的起始位置为0
                    //     end: 30, // 默认显示的结束位置为100
                    //     handleSize: 8, // 滑动条的手柄大小
                    //     height: 10,
                    //     handleStyle: {
                    //         color: '#DCE2E8' // 滑动条的手柄颜色
                    //     },
                    //     xAxisIndex: [0], // 表示控制第一个x轴
                    //     filterMode: 'filter' // 设置为filter模式，即数据超过范围时会被过滤掉
                    // }],
                    legend: {
                        icon: 'rect',
                        itemWidth: 14,
                        itemHeight: 5,
                        itemGap: 13,
                        data: [],
                        right: '4%',
                        textStyle: {
                            fontSize: 12,
                            color: '#F1F1F3'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            lineStyle: {
                                color: '#00c7ff'
                            }
                        },
                        data: this.xData
                    }],
                    yAxis: [{
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#00c7ff'
                            }
                        },
                        axisLabel: {
                            margin: 10,
                            textStyle: {
                                fontSize: 14
                            }
                        },
                        splitLine: {
                            show: false
                        }
                    }],
                    series: [{
                        name: '销售金额',
                        type: 'line',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 5,
                        showSymbol: false,
                        lineStyle: {
                            normal: {
                                width: 1
                            }
                        },
                        areaStyle: {
                            normal: {
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#00DEEE',
                                borderColor: '#00DEEE',
                                borderWidth: 12,
                            }
                        },
                        data: this.yData
                    },
                        {
                            name: '销售数量',
                            type: 'line',
                            smooth: true,
                            symbol: 'circle',
                            symbolSize: 5,
                            showSymbol: false,
                            lineStyle: {
                                normal: {
                                    width: 1
                                }
                            },
                            areaStyle: {
                                normal: {
                                    shadowColor: 'rgba(0, 0, 0, 0.1)',
                                    shadowBlur: 10
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: 'rgba(0, 0, 0, 0)',
                                    borderColor: 'rgba(0, 0, 0, 0)',
                                    borderWidth: 12
                                }
                            },
                            data: this.yData2
                        }]
                }
            },
            // 分类数据详情跳转！
            handleJump(val) {
                 switch (val) {
                   case 0 : this.$router.push('/sale/ticketData') ;break;
                   case 1 : this.$router.push('/sale/ticketData') ;break;
                   case 2 : this.$router.push('/sale/secondSale') ;break;
                   case 3 : this.$router.push('/sale/cardSale') ;break;
                   case 4 : this.$router.push('/sale/shopSale') ;break;
                 }
            },
            // 景区销售数据
            getTjSaleScenicSpotData() {
                tjSaleScenicSpotData().then(res => {
                    console.log('===>', res)
                    this.spotDataList = res.data
                })
            },
            // 获取节假日列表
            getTjHolidayList(year) {
                tjHolidayList({year: year}).then(res => {
                    this.holidayList = res.data
                })
            },
            // 游客总量-日期
            handleDate1() {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.getTjSaleMonthRank();
            },
            chooseYaer1(val) {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.checkDisable = false
                this.checkDisable2 = false
                this.getTjHolidayList(val)
                if (this.monthValue1.length > 0) {
                    var lasyDay = ''
                    lasyDay = this.getLastDay(val, this.monthValue1.toString())
                    console.log('lasyDay', lasyDay)
                    this.date1 = [val + '-' + this.monthValue1.toString() + '-01', val + '-' + this.monthValue1.toString() + '-' + lasyDay];
                }
            },
            handleMonth(val) {
                if (val.length > 0) {
                    this.checkDisable2 = true
                    var lasyDay = ''
                    lasyDay = this.getLastDay(this.yearValue1, val.toString())
                    console.log('lasyDay', lasyDay)
                    this.date1 = [this.yearValue1 + '-' + val.toString() + '-01', this.yearValue1 + '-' + val.toString() + '-' + lasyDay];
                } else {
                    this.checkDisable2 = false
                }
            },
            handleHoliday(val) {
                if (val.length > 0) {
                    this.checkDisable = true
                    this.holidayList.forEach(item => {
                        if (item.name == val.toString()) {
                            this.date1 = [item.start_date, item.end_date];
                        }
                    })
                } else {
                    this.checkDisable = false
                }
            },
            //某年某月最后一天
            getLastDay(year, month) {
                var nyear = year;
                var nmonth = month++;
                if (month > 12) {
                    nmonth -= 12;
                    nyear++;
                }
                var n_date = new Date(nyear, nmonth, 1);
                return (new Date(n_date.getTime() - 1000 * 60 * 60 * 24)).getDate();
            },


            cannelKj(val) {
                if (val == 1) {
                    this.kjvisible1 = false
                }
            },
            sumbitKj(val) {
                if (val == 1) {
                    if (!this.yearValue1) {
                        this.$message.error('请选择年份')
                        return false
                    }
                    if (this.monthValue1.length == 0 && this.holidayValue1.length == 0) {
                        this.$message.error('请选择月份或者节假日')
                        return false
                    }
                    this.kjvisible1 = false
                    this.getTjSaleMonthRank();
                }
            },
        },

        watch: {},


    };
</script>
<style lang="scss" scoped>
    .wrapper {
        .cs-box1 {
            width: 1266px;
            height: 135px;
            margin-bottom: 24px;

            .every-cs1, .every-cs2, .every-cs3 {
                width: 406px;
                height: 135px;
                padding: 23px 23px 23px 129px;

                .pp1 {
                    opacity: 0.7;
                    font-size: 14px;
                    font-weight: normal;
                    text-align: left;
                    color: #ffffff;
                }

                .pp-money {
                    margin: 12px 0px 8px 0px;
                    font-size: 28px;
                    font-weight: normal;
                    text-align: left;
                    color: #1cffff;
                }

                .pp2 {
                    font-size: 16px;
                    font-weight: normal;
                    text-align: left;
                    color: #ffffff;
                }
            }

            .every-cs1 {
                background: url("../assets/sale/jrxs.png") no-repeat;
                background-size: 100% 100%;
            }

            .every-cs2 {
                background: url("../assets/sale/ndsr.png") no-repeat;
                background-size: 100% 100%;
            }

            .every-cs3 {
                background: url("../assets/sale/yfsr.png") no-repeat;
                background-size: 100% 100%;
            }
        }

        .cs-box2 {
            width: 1266px;
            height: 711px;
            background: url("../assets/sale/saleBg1.png") no-repeat;
            background-size: 100% 100%;
            padding: 24px 33px;

            .left-img {
                width: 856px;
                height: 48px;

                .title-img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .right-select {
                width: 296px;
            }
            .right-select2 {
                width: 550px;
            }

            .table-head {
                width: 1200px;
                height: 40px;
                background: #092859;
                border: 0.5px solid #3e85b7;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: #ffffff;

                .head1 {
                    padding-left: 24px;
                    width: 396px;
                }

                .head2 {
                    width: 333px;
                }

                .head3 {
                    width: 140px;
                }

                .head4 {
                    width: 230px;
                }

                .head5 {
                    width: 157px;
                }

                img {
                    width: 6px;
                    height: 9px;
                    margin-left: 3px;
                }
            }

            .imp-info {
                .table-info:nth-child(odd) {
                    width: 1200px;
                    height: 40px;
                    background: #15223c;
                }

                .table-info:nth-child(even) {
                    width: 1200px;
                    height: 40px;
                    background: rgba(21, 34, 60, 0.60);
                }

                .table-info {
                    font-size: 14px;
                    font-weight: normal;
                    text-align: left;
                    color: #ffffff;

                    .head1 {
                        padding-left: 24px;
                        width: 396px;
                        font-size: 14px;
                        text-align: left;
                        color: #3ce6f0;
                    }

                    .head2 {
                        width: 333px;
                    }

                    .head3 {
                        width: 140px;
                    }

                    .head4 {
                        width: 230px;
                    }

                    .head5 {
                        width: 157px;
                    }
                }
            }
        }

        .cs-box3 {
            width: 555px;
            height: 870px;
            background: url("../assets/sale/saleBg2.png") no-repeat;
            background-size: 100% 100%;
            padding: 25px 34px 24px;

            .right1 {
                margin-bottom: 21px;

                .yxssj {
                    display: block;
                    width: 358px;
                    height: 48px;
                }
            }

            .right2 {
                margin-bottom: 21px;

                .flsj {
                    display: block;
                    width: 358px;
                    height: 48px;
                    margin-bottom: 21px;
                }

                .flsjMore {
                    display: block;
                    width: 120px;
                    height: 32px;
                    margin-bottom: 21px;
                }

                .detail1 {
                    .pp3 {
                        opacity: 0.8;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        color: #ffffff;
                    }

                    .middle-box {
                        width: 108px;
                        height: 88px;
                        background: url("../assets/sale/flxqIcon.png") no-repeat;
                        background-size: 100% 100%;

                        font-size: 18px;
                        font-weight: normal;
                        text-align: center;
                        color: #1cffff;
                        padding-top: 8px;
                    }

                    .bottom-box {
                        width: 60px;
                        height: 24px;
                        background: url("../assets/sale/flxqMore.png") no-repeat;
                        background-size: 100% 100%;
                        margin: 0 auto;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        color: #37ecff;
                        line-height: 24px;
                    }
                }
            }

            .right3 {
                .jrjqsj {
                    display: block;
                    width: 488px;
                    height: 48px;
                    margin-bottom: 21px;
                }

                .all-today-jq {
                    padding: 6px 0 7px 0;
                }

                .fenduan1 {
                    width: 108px;

                    font-size: 14px;
                    font-weight: normal;
                    text-align: right;
                    color: #ffffff;
                }

                .fenduan2 {
                    width: 240px;
                    height: 3px;
                    background-color: #063357;
                    position: relative;

                    .per-box {
                        height: 3px;
                        background: linear-gradient(270deg, #00e1ee, #007bfe);
                    }

                    .dian {
                        width: 8px;
                        height: 8px;
                        background: #1cffff;
                        border-radius: 50%;
                        filter: blur(0.75px);
                        position: absolute;
                        top: -3px;
                    }
                }

                .fenduan3 {
                    width: 108px;

                    font-size: 16px;
                    font-weight: normal;
                    text-align: right;
                    color: #1cffff;

                    span {
                        opacity: 0.7;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .mr10 {
        margin-right: 10px;
    }

    .tc {
        text-align: center;
    }

    .mt15 {
        margin-top: 15px;
    }

    .flex {
        display: flex;
    }

    .ac {
        align-items: center;
    }

    .jb {
        justify-content: space-between;
    }

    .fw {
        flex-wrap: wrap;
    }

    .bbox {
        box-sizing: border-box;
    }

    .cr {
        cursor: pointer;
    }

    pdlf24 {
        padding: 0 24px;
    }

    .mr10 {
        margin-right: 10px;
    }

    .mt25 {
        margin-top: 25px;
    }

    .mb25 {
        margin-bottom: 25px;
    }

    .mb10 {
        margin-bottom: 10px;
    }
    .kj-box {
        background: url('../assets/kuaijie-box.png') no-repeat;
        background-size: 100% 100%;
        width: 96px;
        height: 33px;
        margin-left: 12px;
        cursor: pointer;

        p {
            text-align: center;
            line-height: 33px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
        }
    }
</style>

<style lang="scss">
    .right-select .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042C60 !important;
        border: none!important;
        color: #37ECFF!important;
    }

    .right-select .el-input__icon {
        line-height: 28px !important;
    }
    .right-select2 .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042C60 !important;
        border: none!important;
        color: #37ECFF!important;
    }

    .right-select2 .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .all-date-box
    .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .all-date-box
    .el-range-separator {
        color: #37ecff !important;
    }

    .chooseYearBox
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
    }

    .chooseYearBox
    .el-input__icon {
        line-height: 28px !important;
    }

    .chooseYearBox
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .is-selected {
        color: #1989FA;
    }

    .calendar-info {
        font-size: 12px;
    }

    .el-checkbox {
        margin: 0 5px 5px 0;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
    }
</style>
